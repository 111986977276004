import { useCallback } from 'react';

export const useCloseMenuOnBlur = (ref, close) => {
  const closeOnBlur = useCallback(() => {
    if (ref && ref.current && !ref.current.contains(document.activeElement)) {
      close();
    }
  }, [ref, close]);

  return useCallback(() => {
    window.setTimeout(closeOnBlur, 100);
  }, [closeOnBlur]);
};
