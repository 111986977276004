import { Translation } from 'shared/components/translation/translation.component';

import './system-disruption.component.scss';

export const SystemDisruption = (props: {pageNameResourceKey?: ResourceKey, disruptionType: string}) => {  
    if(props.pageNameResourceKey != null){
    return (
            <div className='text-center space-outer-top-xxl' data-testid='system-disruption'>
                <img src='/assets/images/construction-barrier.svg' alt='Construction Barrier Icon' />
                <h1 className='space-outer-top-lg'><Translation resource={props.pageNameResourceKey}/>{" "}<Translation resource="IS_TEMPORARILY_UNAVAILABLE"/></h1>
                <div id="system-disruption">
                    <p><Translation resource="WORKING_TO_FIX"/>
                    <br/>{props.disruptionType === "PAGE_LEVEL_DISRUPTION" ? <Translation resource="CHECK_BACK_IN_THE_MEANTIME"/> : <Translation resource="APOLOGIZE_FOR_INCONVENIENCE"/>}</p>
                </div>
            </div>
        );
    }
}